import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function NoticeEtcMenu({ HiInformationCircle }) {
	const history = useHistory();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	function reloadToClientReqs4InLaborContractsInfo() {
		history.push('/clientReqs4InLaborContractsInfo');
	}

	function reloadToClientReqs4InNewYearLawInfo() {
		history.push('/clientReqs4InNewYearLawInfo');
	}

	function reloadToClientReqs4InInfo() {
		history.push('/clientReqs4InInfo');
	}

	return (
		<>
			<Menu.Item onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
				{!isMobile && <HiInformationCircle className='icon-spacing' />}
				<Dropdown
					style={{ width: '160px' }}
					pointing='top left'
					text='NOTICE'
					open={dropdownOpen}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => reloadToClientReqs4InLaborContractsInfo()}
							text='AI 근로계약서 종류'
						/>
						<Dropdown.Item
							onClick={() => reloadToClientReqs4InNewYearLawInfo()}
							text='2024년 변경 노동법'
						/>
						<Dropdown.Item
							onClick={() => reloadToClientReqs4InInfo()}
							text='SafeHR New 기능'
						/>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		</>
	);
}
